export const breakpoints = {
    xs: "320",
    sm: "640",
    md: "768",
    lg: "1024",
    xl: "1280",
    "2xl": "1536",
  }
  
  export const styleDevices = {
    xs: `(min-width: ${breakpoints.xs+'px'})`,
    sm: `(min-width: ${breakpoints.sm+'px'})`,
    md: `(min-width: ${breakpoints.md+'px'})`,
    lg: `(min-width: ${breakpoints.lg+'px'})`,
    xl: `(min-width: ${breakpoints.xl+'px'})`,
    "2xl": `(min-width: ${breakpoints["2xl"]+'px'})`,
  }
  
  export const respDevices = {
    xs: `minWidth={${breakpoints.xs}}`,
    sm: `minWidth={${breakpoints.sm}}`,
    md: `minWidth={${breakpoints.md}}`,
    lg: `minWidth={${breakpoints.lg}}`,
    xl: `minWidth={${breakpoints.xl}}`,
  }
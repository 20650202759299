import * as THREE from 'three';
import { useEffect, useState, useRef } from "react";

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass.js';
import TWEEN from '@tweenjs/tween.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { bip39 } from '../App';


function WordsCloud({ changeWord, canceledWord, claimedWord }) {
    const [_bip39, setbip39] = useState({})
    const [claimedWords, setClaimedWords] = useState([]);
    const [wordsMeshes, setwordsMeshes] = useState([]);
    const refContainer = useRef(null);

    const BLOOM_SCENE = 1;

    const bloomLayer = new THREE.Layers();
    bloomLayer.set(BLOOM_SCENE);
    const darkMaterial = new THREE.MeshBasicMaterial({ color: 'black' });
    const materials = {};

    let camera, scene, controls, renderer, bloomComposer, finalComposer, raycaster, mouse;

    useEffect(() => {

        initializeScene();
        getBip39();

        return () => {
            cleanupScene();
        };
    }, [])
/*
    useEffect(() => {
        const updateTimer = setInterval(getLatestChange, 5000);

        return () => {
            clearInterval(updateTimer);
        };
    }, []);
*/
    useEffect(() => {
        const canceledWordMesh = wordsMeshes.find((wordMesh) => wordMesh.name === canceledWord);
        if (canceledWordMesh != undefined) {
            canceledWordMesh.claimed = false;
            canceledWordMesh.material.color.set(0xffffff);
            canceledWordMesh.layers.disable(BLOOM_SCENE);
        }
    }, [canceledWord])

    useEffect(() => {
        const claimedWordMesh = wordsMeshes.find((wordMesh) => wordMesh.name === claimedWord);
        if (claimedWordMesh != undefined) {
            claimedWordMesh.claimed = true;
            claimedWordMesh.material.color.set(0xffff00);
            claimedWordMesh.layers.enable(BLOOM_SCENE);
        }
    }, [claimedWord])

    const getBip39 = async () => {
        try {
            bip39.onSnapshot((querySnapshot) => {
                const words = [];
                querySnapshot.forEach((doc) => {
                    console.log(doc)
                    words.push(doc.data());
                })
                setbip39(words);
                render(words);
            })
        } catch (error) {
            console.log(error);
        }
    };


    const getLatestChange = async () => {
        try {
            bip39.where("claimed", "==", true)
                .get()
                .then(async (querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        const wordToUpdate = wordsMeshes.find(mesh => mesh.name === doc.data().word);
                    if (wordToUpdate) {
                        wordToUpdate.claimed = true;
                        wordToUpdate.material.color.set(0xffff00);
                        wordToUpdate.layers.enable(BLOOM_SCENE);
                        //updatedClaimedWords.push(doc.data().word);
                    }
                    })
                })
        } catch (error) {
            console.log(error);
        }
    }

    const initializeScene = () => {
        camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        camera.position.z = 50;

        renderer = new THREE.WebGLRenderer({ antialias: true, pixelRatio: window.devicePixelRatio });
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setPixelRatio(window.devicePixelRatio);
        document.body.appendChild(renderer.domElement);

        scene = new THREE.Scene();
        raycaster = new THREE.Raycaster();
        mouse = new THREE.Vector2();

        controls = new OrbitControls(camera, renderer.domElement);

        window.addEventListener('resize', onWindowResize);
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('click', onClick);

        const renderScene = new RenderPass(scene, camera);
        const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 1.5, 0.4, 0.85);
        bloomPass.threshold = 0.5;
        bloomPass.strength = 0.3;
        bloomPass.radius = 0.5;

        bloomComposer = new EffectComposer(renderer);
        bloomComposer.renderToScreen = false;
        bloomComposer.addPass(renderScene);
        bloomComposer.addPass(bloomPass);

        const mixPass = new ShaderPass(
            new THREE.ShaderMaterial({
                uniforms: {
                    baseTexture: { value: null },
                    bloomTexture: { value: bloomComposer.renderTarget2.texture },
                },
                vertexShader: `
                    varying vec2 vUv;
                    void main() {
                        vUv = uv;
                        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                    }`,
                fragmentShader: `
                    uniform sampler2D baseTexture;
                    uniform sampler2D bloomTexture;
                    varying vec2 vUv;
                    void main() {
                        gl_FragColor = (texture2D(baseTexture, vUv) + vec4(1.0) * texture2D(bloomTexture, vUv));
                    }`,
            }),
            'baseTexture'
        );
        mixPass.needsSwap = true;

        const outputPass = new OutputPass();

        finalComposer = new EffectComposer(renderer);
        finalComposer.addPass(renderScene);
        finalComposer.addPass(mixPass);
        finalComposer.addPass(outputPass);
    };

    const cleanupScene = () => {
        scene.children.forEach((child) => {
            if (child instanceof THREE.Mesh) {
                scene.remove(child);
                child.geometry.dispose();
                child.material.dispose();
            }
        });
        renderer.domElement.remove();
        renderer.dispose();
        window.removeEventListener('resize', onWindowResize);
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('click', onClick);
    }

    function onWindowResize() {
        const width = window.innerWidth;
        const height = window.innerHeight;

        camera.aspect = width / height;
        camera.updateProjectionMatrix();

        renderer.setSize(width, height);

        bloomComposer.setSize(width, height);
        finalComposer.setSize(width, height);
    }

    function onMouseMove(event) {
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
    }

    function onClick(event) {
        raycaster.setFromCamera(mouse, camera);

        const intersects = raycaster.intersectObjects(scene.children, true);

        if (intersects.length > 0) {
            const selectedWord = intersects[0].object;

            // Reset color for all words
            scene.children.forEach(word => {
                if (word && word.material) {
                    if (!word.claimed) {
                        word.material.color.set(0xffffff);
                    }
                }
            });

            // Change the color of the selected word
            if (selectedWord && selectedWord.material) {
                if (!selectedWord.claimed) {
                    selectedWord.material.color.set(0x00ff00);
                }
            }

            // Calculate the width of the text geometry
            const boundingBox = new THREE.Box3().setFromObject(selectedWord);
            const width = boundingBox.max.x - boundingBox.min.x;

            // Animate camera to focus on the selected word
            animateCameraToWord(selectedWord.position, width);

            //Send selected word to the Parent Component
            changeWord(selectedWord.name.toUpperCase(), selectedWord.claimed);

        }
    }

    function animateCameraToWord(targetPosition, textWidth) {
        const initialPosition = camera.position.clone();

        // Calculate the distance to place the camera in front of the word
        const distance = 1.5; // Adjust as needed

        const lookAtPosition = {
            x: targetPosition.x + textWidth / 2,
            y: targetPosition.y,
            z: targetPosition.z
        };
        //controls.target.set(targetPosition.x + distance / 2,targetPosition.y,targetPosition.z);

        const tweenDuration = 1000; // milliseconds

        new TWEEN.Tween(controls.target)
            .to({ x: targetPosition.x + textWidth / 2, y: targetPosition.y, z: targetPosition.z }, tweenDuration)
            .easing(TWEEN.Easing.Quartic.InOut)
            .start()


        new TWEEN.Tween(camera.position)
            .to({ x: targetPosition.x + textWidth / 2, y: targetPosition.y, z: targetPosition.z + distance }, tweenDuration)
            .easing(TWEEN.Easing.Quartic.InOut)
            .onUpdate(() => {
                camera.lookAt(lookAtPosition);
            })
            .onComplete(() => {
                // Ensure the camera's up vector is parallel to the Y-axis
                camera.up.set(0, 1, 0);
            })
            .start();
    }

    const render = (data) => {
        console.log('rendering')
        let font;
        const wordsData = generateWords(data, 2048);
        const fontloader = new FontLoader();
        fontloader.load('/fonts/Andale_Mono_Regular.json', (loadedFont) => {
            font = loadedFont;
            // Initialize the scene after the font is loaded
            createCloud(wordsData);
            animate();
        });


        function generateWords(data, count) {
            const words = data;
            const shuffledWords = [...words].sort(() => Math.random() - 0.5);

            const generatedWords = [];
            const minDistance = 1;

            for (let i = 0; i < count; i++) {
                const word = shuffledWords[i].word;
                const claimed = shuffledWords[i].claimed;

                // Generate random position
                const position = new THREE.Vector3(
                    (Math.random() - 0.5) * 40,
                    (Math.random() - 0.5) * 40,
                    (Math.random() - 0.5) * 40
                );

                // Check distance from other words
                let tooClose = false;
                for (const existingWord of generatedWords) {
                    const distanceSquared = position.distanceToSquared(existingWord.position);
                    if (distanceSquared < minDistance * minDistance) {
                        tooClose = true;
                        break;
                    }
                }

                // If not too close, add to the list
                if (!tooClose) {
                    generatedWords.push({ word, claimed, position: position });
                } else {
                    i--; // Retry for a new position
                }
            }

            return generatedWords;
        }


        function createCloud(words) {
            const renderScene = new RenderPass(scene, camera);

            const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 1.5, 0.4, 0.85);
            bloomPass.threshold = 0.5; // Adjust as needed
            bloomPass.strength = 0.3;   // Adjust as needed
            bloomPass.radius = 0.5;    // Adjust as needed
            bloomPass.renderToScreen = true;  // Set this to false

            bloomComposer = new EffectComposer(renderer);
            bloomComposer.renderToScreen = false;
            bloomComposer.addPass(renderScene);
            bloomComposer.addPass(bloomPass);

            const mixPass = new ShaderPass(
                new THREE.ShaderMaterial({
                    uniforms: {
                        baseTexture: { value: null },
                        bloomTexture: { value: bloomComposer.renderTarget2.texture },
                    },
                    vertexShader: `
                varying vec2 vUv;
                void main() {
                    vUv = uv;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                }`,
                    fragmentShader: `
                uniform sampler2D baseTexture;
                uniform sampler2D bloomTexture;
                varying vec2 vUv;
                void main() {
                    gl_FragColor = (texture2D(baseTexture, vUv) + vec4(1.0) * texture2D(bloomTexture, vUv));
                }`,
                    defines: {},
                }),
                'baseTexture'
            );
            mixPass.needsSwap = true;

            const outputPass = new OutputPass();

            finalComposer = new EffectComposer(renderer);
            finalComposer.addPass(renderScene);
            finalComposer.addPass(mixPass);
            finalComposer.addPass(outputPass);

            let tmpwordsMeshes = []

            function addWordWithDelay(index) {
                if (index < words.length) {
                    var wordData = words[index];

                    if (font) {
                        var textGeometry = new TextGeometry(wordData.word, {
                            font: font,
                            size: 0.2,
                            height: 0.05,
                        });



                        var isClaimed = wordData.claimed === true;

                        // Use a different material for claimed words
                        var textMaterial = new THREE.MeshBasicMaterial({
                            color: isClaimed ? 0xffff00 : 0xffffff,
                        });
                        let textMesh = new THREE.Mesh(textGeometry, textMaterial);
                        textMesh.name = wordData.word;
                        textMesh.claimed = wordData.claimed;
                        textMesh.position.copy(wordData.position);
                        scene.add(textMesh);
                        tmpwordsMeshes.push(textMesh);
                        setwordsMeshes(tmpwordsMeshes);

                        if (isClaimed) {
                            textMesh.layers.enable(BLOOM_SCENE);
                        } else {
                            //textMesh.layers.disable(BLOOM_SCENE);
                        }

                        // Continue adding words with a delay
                        setTimeout(() => {
                            addWordWithDelay(index + 1);
                        }, 1); // Adjust the delay (in milliseconds) between words
                    }
                }
                else {
                    setTimeout(() => {
                        scene.children.forEach(word => {
                            if (word && word.material) {
                                word.material.color.set(0x00ff00);
                            }
                        });
                    });
                    setTimeout(() => {
                        scene.children.forEach(word => {
                            if (word && word.material) {
                                if (!word.claimed) {
                                    word.material.color.set(0xffffff);
                                } else {
                                    word.material.color.set(0xffff00);
                                }

                            }
                        });
                    }, 200);
                }
            }

            // Start adding words progressively
            addWordWithDelay(0);
        }


        function animate() {
            scene.traverse(darkenNonBloomed);
            bloomComposer.render();
            scene.traverse(restoreMaterial);
            requestAnimationFrame(animate);

            TWEEN.update(); // Update the tween manager

            controls.update();

            finalComposer.render();
        }

        function cleanupOldWords() {
            scene.children.forEach((child) => {
                if (child instanceof THREE.Mesh) {
                    scene.remove(child);
                    child.geometry.dispose();
                    child.material.dispose();
                }
            });
        }

        function darkenNonBloomed(obj) {

            if (obj.isMesh && bloomLayer.test(obj.layers) === false) {

                materials[obj.uuid] = obj.material;
                obj.material = darkMaterial;

            }

        }

        function restoreMaterial(obj) {

            if (materials[obj.uuid]) {

                obj.material = materials[obj.uuid];
                delete materials[obj.uuid];

            }

        }
    }
}

export default WordsCloud;
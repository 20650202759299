import React from "react";
import { Link, Outlet } from 'react-router-dom';

import Navigation from "../components/navigation";

import styled from 'styled-components';
import {breakpoints} from "../components/constants";

const PageContent = styled.div`
    @media only screen and (min-width: ${breakpoints.md}px){
        padding-top: 50px;
        padding-bottom: 50px;
    }
`

const Root = () => {
    return (
        <div >
            <Navigation/>
            <PageContent>
                <Outlet />
            </PageContent>

            
        </div>
    );
};

export default Root;
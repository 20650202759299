import { Alchemy } from "alchemy-sdk";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import { Link, useParams, useNavigate  } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { alchemySettings } from "../App";
import CypherDudesArtifact from '../web3/abi/CypherDudes.json';
import CypherDudesArchivesArtifact from '../web3/abi/CypherDudesArchives.json';
import CypherDudesBitArtifact from '../web3/abi/CypherDudesBit.json';
import CypherDudesBitRendererArtifact from '../web3/abi/CypherdudesBitRenderer.json';
import CypherDudesRendererArtifact from '../web3/abi/CypherdudesRenderer.json';
import { contractAddresses } from '../web3/contractsAddresses';

import { ReactComponent as Loader } from '../components/svg/loader.svg';

import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";
import ArchivesGalleryTokenCard from "../components/archivesGalleryTokenCard";

import Background from "../components/background";

const utilities = require("../components/utilities");
const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

const TextLink = styled(Link)`
margin-bottom :10px;
&:focus, &:hover, &:visited, &:link, &:active {
    color:#fff;
}
&:hover{
    color:#00ff00;
}
`;

const DudeLink = styled(Link)`
color : #00ff00;
&:focus, &:hover, &:visited, &:link, &:active {
    color:#00ff00;
}
&:hover{
    color:#fff;
}
`;

const Folder = ({ tokenID }) => {

    const alchemy = new Alchemy(alchemySettings);

    const navigate = useNavigate();

    Modal.setAppElement('#root');

    const params = useParams();
    const id = params.tokenId;

    const [tokenURI, setTokenURI] = useState({});
    const [tokenName, setTokenName] = useState('');
    const [trackValues, setTrackValues] = useState(0);
    const [supply, setSupply] = useState(0);
    const [tokens, setTokens] = useState([]);
    const [dudeTokens, setDudeTokens] = useState([]);
    const [owner, setOwner] = useState({});

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider()
    const provider = new ethers.BrowserProvider(walletProvider);
    const infuraProvider = new ethers.JsonRpcProvider("https://mainnet.infura.io/v3/a401f99315474f15bf32bb81404d0886");
    const LlamaProvider = new ethers.JsonRpcProvider("https://eth.llamarpc.com/sk_llama_e95422cce70e51a648a78b78dc29799f");
    const openProvider = new ethers.BrowserProvider(window.ethereum);

    const cypherDudesReadContract = new ethers.Contract(
        contractAddresses.CypherDudes,
        CypherDudesArtifact.abi,
        LlamaProvider
    );

    const cypherDudesRendererReadContract = new ethers.Contract(
        contractAddresses.CypherDudesRender,
        CypherDudesRendererArtifact.abi,
        LlamaProvider
    );

    const cypherDudesBitReadContract = new ethers.Contract(
        contractAddresses.CypherDudesBit,
        CypherDudesBitArtifact.abi,
        LlamaProvider
    );

    const cypherDudesBitRendererReadContract = new ethers.Contract(
        contractAddresses.CypherDudesBitRender,
        CypherDudesBitRendererArtifact.abi,
        LlamaProvider
    );

    const cypherDudesArchivesReadContract = new ethers.Contract(
        contractAddresses.CypherDudesArchives,
        CypherDudesArchivesArtifact.abi,
        LlamaProvider
    );

    const [txStatus, setTxStatus] = useState('idle');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [archiveModalIsOpen, setArchiveIsOpen] = useState(false);
    const [privateArchiveModalIsOpen, setPrivateArchiveIsOpen] = useState(false);
    const [chartModalIsOpen, setChartModalIsOpen] = useState(false);

    let subtitle;
    const customStyles = {
        overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: '#000000B3'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            border: '1px solid #00ff00',
            background: '#000',
            borderRadius: '0px',
            transform: 'translate(-50%, -50%)',
        },
    };

    const customChartStyles = {
        overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: '#000000B3'
        },
        content: {
            innerWidth: '95%',
            innerHeight: '95%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            border: '1px solid #00ff00',
            background: '#000',
            borderRadius: '0px',
            transform: 'translate(-50%, -50%)',
        },
    };

    useEffect(() => {
        let navs = document.getElementsByTagName('nav');
        navs[0].style.display = "block";
    }, []);

    useEffect(() => {
        getDudeArchives();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            _getTokenURI();
        }, 500);

    }, []);

    useEffect(() => {
        var status = document.getElementById('status')
        if (status != null) {
            var statusBtn = document.getElementById('statusBtn')
            status.innerHTML = txStatus;
            statusBtn.style.display = 'block';
        }
    }, [txStatus]);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#00ff00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    function openArchiveModal() {
        setArchiveIsOpen(true);
    }

    function afterOpenArchiveModal() {
        subtitle.style.color = '#00ff00';
    }

    function closeArchiveModal() {
        setArchiveIsOpen(false);
    }

    function openPrivateArchiveModal() {
        setPrivateArchiveIsOpen(true);
    }

    function afterOpenPrivateArchiveModal() {
        subtitle.style.color = '#00ff00';
    }

    function closePrivateArchiveModal() {
        setPrivateArchiveIsOpen(false);
    }

    function openChartModal() {
        setChartModalIsOpen(true);
    }

    function afterOpenChartModal() {
        subtitle.style.color = '#00ff00';
    }

    function closeChartModal() {
        setChartModalIsOpen(false);
    }

    const getValueByTraitType = (jsonData, traitType) => {
        const trait = jsonData.find(trait => trait.trait_type === traitType)?.value || '';
        return trait;
    }

    const getAddress = async (address) => {
        let resolvedAddress = await infuraProvider.lookupAddress(address);
        const holder = {
            longAddress: address,
            shortAddress: resolvedAddress === null ? address.slice(0, 6) + "..." + address.slice(-4) : resolvedAddress

        }
        return holder
    }

    const _getTokenURI = async () => {
        setTokenURI({});
        if (id < 1728) {
            try {
                let tokenURI = await cypherDudesReadContract.tokenURI(id);
                const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                let tokenData = await cypherDudesReadContract.tokenData(id);
                let holderAddress = await cypherDudesReadContract.ownerOf(id);
                setOwner(await getAddress(holderAddress));
                const globalProgression = ethers.toNumber(tokenData[1]);
                const tokenEntry = {
                    id: uuidv4(),
                    tokenId: id.toString(),
                    name: tokenURIJSONDecoded.name,
                    gridSize: getValueByTraitType(tokenURIJSONDecoded.attributes, 'Grid Size'),
                    gridResolution: getValueByTraitType(tokenURIJSONDecoded.attributes, 'Grid Resolution'),
                    levelProgression: getValueByTraitType(tokenURIJSONDecoded.attributes, 'Level Progression'),
                    action: getValueByTraitType(tokenURIJSONDecoded.attributes, 'Action'),
                    layer1: getValueByTraitType(tokenURIJSONDecoded.attributes, 'Layer 1'),
                    layer2: getValueByTraitType(tokenURIJSONDecoded.attributes, 'Layer 2'),
                    layer3: getValueByTraitType(tokenURIJSONDecoded.attributes, 'Layer 3'),
                    secretWord: getValueByTraitType(tokenURIJSONDecoded.attributes, 'Secret Word'),
                    bitEnvironment: getValueByTraitType(tokenURIJSONDecoded.attributes, 'Bit Environment'),
                    bitPalette: getValueByTraitType(tokenURIJSONDecoded.attributes, 'Bit Palette'),
                    lsb: getValueByTraitType(tokenURIJSONDecoded.attributes, 'LSB'),
                    animationURL: animationURL
                }
                setTokenURI(tokenEntry);
                setTokenName(tokenURIJSONDecoded.name);
                setTrackValues(globalProgression);
            } catch (error) {
                console.log(error)
            }
        } else {
            try {
                let tokenURI = await cypherDudesBitReadContract.tokenURI(id);
                const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                let tokenData = await cypherDudesBitReadContract.tokenData(id);
                let holderAddress = await cypherDudesBitReadContract.ownerOf(id);
                setOwner(await getAddress(holderAddress));
                const globalProgression = ethers.toNumber(tokenData[1]);
                const tokenEntry = {
                    id: uuidv4(),
                    tokenId: id.toString(),
                    name: tokenURIJSONDecoded.name,
                    gridSize: getValueByTraitType(tokenURIJSONDecoded.attributes, 'Grid Size'),
                    gridResolution: getValueByTraitType(tokenURIJSONDecoded.attributes, 'Grid Resolution'),
                    levelProgression: getValueByTraitType(tokenURIJSONDecoded.attributes, 'Level Progression'),
                    action: getValueByTraitType(tokenURIJSONDecoded.attributes, 'Action'),
                    layer1: getValueByTraitType(tokenURIJSONDecoded.attributes, 'Layer 1'),
                    layer2: getValueByTraitType(tokenURIJSONDecoded.attributes, 'Layer 2'),
                    layer3: getValueByTraitType(tokenURIJSONDecoded.attributes, 'Layer 3'),
                    secretWord: getValueByTraitType(tokenURIJSONDecoded.attributes, 'Secret Word'),
                    bitEnvironment: getValueByTraitType(tokenURIJSONDecoded.attributes, 'Bit Environment'),
                    bitPalette: getValueByTraitType(tokenURIJSONDecoded.attributes, 'Bit Palette'),
                    lsb: getValueByTraitType(tokenURIJSONDecoded.attributes, 'LSB'),
                    animationURL: animationURL
                }
                setTokenURI(tokenEntry);
                setTokenName(tokenURIJSONDecoded.name);
                setTrackValues(globalProgression);
            } catch (error) {
                console.log(error)
            }
        }
    }

    const getDudeArchives = async () => {
        let fetchedArchives = [];
        let fetchedTokens = [];
        try {
            setDudeTokens([]);
            const _supply = Number(await cypherDudesArchivesReadContract.totalSupply());
            setSupply(_supply);

            if (_supply === 0) {
                return;
            }
            for (let i = 0; i < _supply; i++) {
                try {
                    let archiveData = await cypherDudesArchivesReadContract.archiveData(i);
                    if (parseInt(id) === Number(archiveData[0])) {
                        let _message;
                        if (archiveData[5]) {
                            const messageData = await cypherDudesArchivesReadContract.getMessageData(archiveData[2]);
                            _message = await cypherDudesReadContract.decrypt(archiveData[6], messageData[2]);
                            const archiveEntry = {
                                archiveId: i,
                                fileId: Number(archiveData[1]),
                                checksum: archiveData[2],
                                blockNumber: archiveData[3],
                                timeStamp: Number(archiveData[4]),
                                freeAccess: archiveData[5],
                                name: archiveData[6],
                                message: _message
                            }
                            fetchedArchives.push(archiveEntry);
                            setDudeTokens([...fetchedArchives]);
                        } else {
                            try {
                                let tokenURI = await cypherDudesArchivesReadContract.tokenURI(i);
                                const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                                const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                                const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                                const tokenEntry = {
                                    id: uuidv4(),
                                    tokenId: i.toString(),
                                    name: tokenURIJSONDecoded.name,
                                    traits: tokenURIJSONDecoded.attributes,
                                    animationURL: animationURL
                                }
                                fetchedTokens.push(tokenEntry);
                                setTokens([...fetchedTokens]);//
                            } catch (error) {
                                console.log(error)
                            }
                        }
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const checkDude = () => {
        navigate(`/cypherdude/${id}`)
    }

    window.addEventListener("keydown", function (e) {
        if (["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(e.code) > -1) {
            e.preventDefault();
        }
    }, false);

    return (
        <div>
            <Background />
            <div className="collectionContainer">
                <div className="DudeHeader">
                    <div className='smDudeCard'>
                        <iframe id="tokenIframe" sandbox="allow-scripts allow-same-origin" frameBorder="0" srcDoc={tokenURI.animationURL}></iframe>
                    </div>
                    <div className="dudeInfo">
                        <DudeLink onClick={checkDude}>{tokenName}</DudeLink>
                        <div className='MyFolderTokenInfo'>
                            <div className="dudeInfoChunk">
                                <p>Owner : <TextLink to={`https://opensea.io/${owner.longAddress}`}>{owner.shortAddress}</TextLink></p>
                            </div>
                            <div className="dudeInfoChunk">
                                <p>{dudeTokens.length > 0 ? `${dudeTokens.length } Public Archive${dudeTokens.length >0?'s':''}`:`No Public Archives found`}</p>
                            </div>
                        </div>

                        <p className='firstLine'>TRAITS :</p>
                        <div className='MyFolderTokenInfo'>
                            <div className="dudeInfoChunk">
                                <p>• Grid size : {tokenURI.gridSize}</p>
                                <p>• Grid resolution : {tokenURI.gridResolution}</p>
                                <p>• Level progression : {tokenURI.levelProgression} / 32</p>
                                <p>• Action : {tokenURI.action}</p>
                            </div>
                            <div className="dudeInfoChunk">
                                <p>• Layer 1 : {tokenURI.layer1}</p>
                                <p>• Layer 2 : {tokenURI.layer2}</p>
                                <p>• Layer 3 : {tokenURI.layer3}</p>
                                <p>• Secret Word : {tokenURI.secretWord}</p>
                            </div>
                            <div className="dudeInfoChunk">
                                <p>• Bit Environment : {tokenURI.bitEnvironment}</p>
                                <p>• Bit Palette : {tokenURI.bitPalette}</p>
                                <p>• LSB : {tokenURI.lsb > 0 ? '01011000 01011000' : '01011000 01011001'}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="collectionContainer"><p className="highlightInfo">PUBLIC FOLDER</p></div>

            <div className="collectionContainer">
                {dudeTokens.length < 1 ?
                    <div>
                        <div className="loader">
                            <Loader />
                        </div>
                    </div>
                    : dudeTokens.map((archive, id) => {
                        document.getElementById('spinner').style.display = "none";
                        return <ArchivesGalleryTokenCard dudeTokens={archive} key={id} />
                    })}
                <span id="spinner">Loading...</span>
            </div>
        </div>
    )
}

export default Folder;
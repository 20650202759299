import firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {

    apiKey: "AIzaSyCglR392LEiuoDcYCXxW3lJ0nDWWjU9CSk",
  
    authDomain: "cypherdudes.firebaseapp.com",
  
    projectId: "cypherdudes",
  
    storageBucket: "cypherdudes.appspot.com",
  
    messagingSenderId: "106197988596",
  
    appId: "1:106197988596:web:0f30d7051caa64d0fd9aa1"
  
  };
  
  firebase.initializeApp(firebaseConfig);
  export default firebase;
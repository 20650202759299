import { Link } from 'react-router-dom';
import styled from 'styled-components';
const utilities = require("./utilities");

const TokenCardLink = styled(Link)`
display:block;
margin-bottom :10px;
margin-left: 5px;
font-size:16px;
&:focus, &:hover, &:visited, &:link, &:active {
    color:#fff;
}
&:hover{
    color:#00ff00;
}
`;

const ClaimLink = styled(Link)`
  text-decoration: none;
  cursor: pointer
  &:focus, &:visited, &:link, &:active {
      text-decoration: none;
  }
`;

const getValueByTraitType = (jsonData, traitType) => jsonData.traits.find(trait => trait.trait_type === traitType)?.value || null;



const LiveTokenCard = ({tokens}) => (
        <div className='TokenRow'>
            <div className='MytokenCard'><iframe id={tokens.tokenId} sandbox="allow-scripts allow-same-origin" frameBorder="0" srcDoc={tokens.animationURL}></iframe></div>
            <div className='MainTokenInfo'>
                <TokenCardLink to={`/cypherdude/${tokens.tokenId}`} className='TokenName'>{tokens.name}</TokenCardLink>
                <ClaimLink to={`/claim_word/${tokens.tokenId}`}>
                    {getValueByTraitType(tokens, 'Secret Word') === null?
                    <button className="uxBtn">CLAIM WORD</button>:''
                }
                    
                </ClaimLink>
            </div>
            <div className='MyTokenInfo'>
                <p className='firstLine'>TRAITS :</p>
                <p>Grid size : {getValueByTraitType(tokens, 'Grid Size')}</p>
                <p>Grid resolution : {getValueByTraitType(tokens, 'Grid Resolution')}</p>
                <p>Level progression : {getValueByTraitType(tokens, 'Level Progression')} / 32</p>
                <p>Action : {getValueByTraitType(tokens, 'Action')}</p>
                <p>Layer 1 : {getValueByTraitType(tokens, 'Layer 1')}</p>
                <p>Layer 2 : {getValueByTraitType(tokens, 'Layer 2')}</p>
                <p>Layer 3 : {getValueByTraitType(tokens, 'Layer 3')}</p>
                <p>Secret word : {getValueByTraitType(tokens, 'Secret Word')}</p>
                <p>Bit environment : {getValueByTraitType(tokens, 'Bit Environment')}</p>
                <p>Bit Palette : {getValueByTraitType(tokens, 'Bit Palette')}</p>
            </div>
        </div>
    )
export default LiveTokenCard;

//import * as fs from 'node:fs';
//import { Zlib } from 'zlib';
import { ethers } from "ethers";

export const stringToBytes = (str) => {
	return ethers.hexlify(ethers.toUtf8Bytes(str));
}

export const bytesToString = (str) => {
	return ethers.toUtf8String(str)
}

export const emptyBytes = () => {
	return ethers.hexlify(ethers.toUtf8Bytes(""));
}
/*
exports.readFile = (path) => {
	return fs.readFileSync(path, { encoding: 'utf8' });
}

exports.writeFile = (path, data) => {
	fs.writeFileSync(path, data);
}
*/
export const emptyAddress = "0x0000000000000000000000000000000000000000"

export const parseBase64DataURI = (uri) => {
	const data = uri.split("base64,")[1]
	const buff = Buffer.from(data, 'base64');
	return buff.toString('ascii');
}

export const parseEscapedDataURI = (uri) => {
	const data = uri.split("data:")[1].split(",")[1]
	return decodeURIComponent(data)
}

export const chunkSubstr = (str, size) => {
	return str.split(new RegExp("(.{"+size.toString()+"})")).filter(O=>O);
}

export const toBase64String = (data) => {
	return Buffer.from(data).toString('base64')
}
/*
exports.toGZIPBase64String = (data) => {
	return Zlib.deflateSync(data).toString('base64');
}*/

export const toURLSafe = (data) => {
	return encodeURIComponent(data)
}

export const toURLSafeDouble = (data) => {
	return encodeURIComponent(encodeURIComponent(data))
}

export const parseDoubleURLEncodedDataURI = (uri) => {
	let firstDecode = decodeURIComponent(uri)
	const data = firstDecode.split("data:")[1].split(",")[1]
	return decodeURIComponent(data)
}
import React from "react";
import './css/index.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {Network} from "alchemy-sdk";

import Root from "./routes/root";
import ErrorPage from './routes/error-page';
import ClaimWord from "./routes/claimword";
import Collection from "./routes/collection";
import MyCypherdudes from "./routes/my_cypherdudes";
import Cypherdude from "./routes/cypherdude";
import CypherdudeFS from "./routes/cypherdude_fullscreen";
import WhiteList from "./routes/whitelist";
import About from "./routes/about";
import Admin from "./routes/admin";
import LeaderBoard from "./routes/leaderboard";
import Archives from "./routes/archives";
import ArchivesCollection from "./routes/archives_collection";
import Archive from "./routes/archive";
import Folder from "./routes/folder";

import firebase from "./Firebase";

import {superAdminAddress} from './web3/adminAddresses';

import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";

const whitelistDB = firebase.firestore().collection('whitelist');
const tokenDB = firebase.firestore().collection('tokens');
const thumbnailDB = firebase.firestore().collection('thumbnailDB');
const bip39 = firebase.firestore().collection('bip39');

const alchemySettings = {
  apiKey : "uS4Hx1reTp_lNrzli7vz_QCCbQBDblhZ",
  network: Network.ETH_MAINNET,
}

function App() {
  const [superAdmin, setSuperAdmin] = useState(false);

  const projectId = process.env.REACT_APP_WEB3MODAL_PROJECT_ID;

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider()
  const provider = new ethers.BrowserProvider(walletProvider);

  const mainnet = {
    chainId: 1,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://eth.llamarpc.com/sk_llama_e95422cce70e51a648a78b78dc29799f'
  }
  const metadata = {
    name: "CYPHERDUDES"
  }

  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [mainnet],
    projectId,
    themeMode: 'dark',
    themeVariables: {
      '--w3m-font-family': 'Andale Mono',
      '--w3m-font-size-master': '13px',
      '--w3m-border-radius-master': '1px',
      '--w3m-accent': '#00FF00',
    }
  })

  useEffect(() => {
    isSuperAdmin();
  }, []);

  useEffect(() => {
    isSuperAdmin();
  }, [address]);

  useEffect(() => {
    isSuperAdmin();
  }, [isConnected]);

  const isSuperAdmin = () =>{
    if(isConnected){
      if(address === superAdminAddress){
        setSuperAdmin(true);
      } else {
        setSuperAdmin(false);
      }
    }
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <About  />,
        },
        {
          path: 'about',
          element: <About/>,
        },
        {
          path: 'collection/:paginator',
          element: <Collection  />,
        },
        {
          path: 'my_cypherdudes',
          element: <MyCypherdudes />,
        },
        {
          path: 'claim_word/:tokenId',
          element: <ClaimWord />,
        },
        {
          path: 'cypherdude/:tokenId',
          element: <Cypherdude  />,
        },
        {
          path: 'wh1t3l12t',
          element: <WhiteList  />,
        },
        {
          path: '4dm1n',
          element: <Admin auth={superAdmin}  />,
        },
        {
          path: 'leaderboard',
          element: <LeaderBoard />,
        },
        {
          path: 'archives',
          element: <Archives />,
        },
        {
          path: 'archives_collection/:paginator',
          element: <ArchivesCollection />,
        },
        {
          path: 'folder/:tokenId',
          element: <Folder />,
        },
        {
          path: 'archive/:tokenId',
          element: <Archive />,
        },
        {
          path: 'cypherdudeFS/:tokenId',
          element: <CypherdudeFS  />,
        },
      ]
    },
    {
      path:"*",
      element: <About />,
    }

  ])

  return (
    <>
      <RouterProvider router={router} />
    </>
  )

}

export {whitelistDB, tokenDB, thumbnailDB, bip39, alchemySettings};
export default App;
import React from "react";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Background from "../components/background";
import Countdown from "../components/countdown";
import MainBoard from "../components/mainBoard";
import Modal from 'react-modal';
import {v4 as uuidv4} from 'uuid';

import { whitelistDB } from "../App";

import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";

const FollowLink = styled(Link)`
display:block;
margin-top :30px;
&:focus, &:hover, &:visited, &:link, &:active {
    color:#fff;
}
&:hover{
    color:#00ff00;
}
`;

const WhiteList = () => {

    Modal.setAppElement('#root');

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider()
    const provider = new ethers.BrowserProvider(walletProvider);

    const [userBalance, setUserBalance] = useState(false)
    const [txStatus, setTxStatus] = useState('idle');
    const [txError, setTxError] = useState(undefined);
    const [response, setResponse] = useState('');

    const [modalIsOpen, setIsOpen] = useState(false);

    let subtitle;

    const customStyles = {
        overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: '#000000B3'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            border: '1px solid #00ff00',
            background: '#000',
            borderRadius: '0px',
            transform: 'translate(-50%, -50%)',
        },
    };

    useEffect(() => {
        checkBalance();
    }, [isConnected]);

    useEffect(() => {
        checkBalance();
    }, [address]);

    useEffect(() => {
        var status = document.getElementById('status')
        if (status != null) {
            var statusBtn = document.getElementById('statusBtn')
            status.innerHTML = txStatus;
            statusBtn.style.display = 'block';
        }
    }, [txStatus]);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#00ff00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const checkBalance = async () => {
        if (!isConnected) {
            return;
        }
        try {
            let userWeiBalance = await provider.getBalance(address);
            let userEthBalance = ethers.formatEther(userWeiBalance);
            if (userEthBalance > 0.031337) {
                setUserBalance(true)
            }
        } catch (error) {
            setTxError(error);
        }
    }

    const requestWhitelist = async () => {
        await checkBalance();
        if (!userBalance) {
            return;
        }
        try {
            openModal();
            const newEntry = { address: address, id: uuidv4() }
            if (newEntry.address.match(/^0x[a-fA-F0-9]{40}$/)) {
                console.log('valid address');
                let i = 0;
                whitelistDB.where("address", "==", newEntry.address)
                    .get()
                    .then(function (querySnapshot) {
                        querySnapshot.forEach(function (doc) {
                            i++;
                        })
                        if (i < 1) {
                            console.log('address candidate');
                            whitelistDB.doc(newEntry.id).set(newEntry)
                            setTxStatus('Whitelist slot secured')
                        }else {
                            setTxStatus('Address already whitelisted')
                        }
                    })
            }
        } catch (error) {
            setTxStatus('Something went wrong, please try again')
        }

    }

    return (
        <div>
            <MainBoard />
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                style={customStyles}
                contentLabel="Inscription Complete">
                <h2 id="status" ref={(_subtitle) => (subtitle = _subtitle)}>REQUEST PENDING</h2>
                <button id="statusBtn" className="uxBtn modal" onClick={closeModal}>CLOSE</button>
            </Modal>
            <div className="CountDownContainer">
                <h1>
                    CYPHERDUDES WILL LAUNCH ON 02-22-2024
                </h1>
                <Countdown />
                <div>
                    {
                        !isConnected ? <button className="disabledClaimWordUxBtn">PLEASE CONNECT YOUR WALLET</button> : userBalance ? <button className="uxBtn" onClick={requestWhitelist} >REQUEST WH1T3 L12T SPOT</button> :
                            <button className="disabledClaimWordUxBtn">PLEASE FUND YOUR WALLET WITH AT LEAST 0.031337 ETH</button>
                    }
                </div>
                <FollowLink to="https://twitter.com/CypherDudes" target="_blank">Follow @CYPHERDUDES on X to get all the latest infos</FollowLink>
            </div>

        </div>
    )
}

export default WhiteList;
import { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { breakpoints } from './constants';
import { ethers } from "ethers";
import { superAdminAddress } from '../web3/adminAddresses';
import LogoLink from './logoLink';
import etherscan from './img/etherscan.png';
import os_Logo from './img/OS_Logo.png';
import { Sidebar } from 'react-pro-sidebar';
import logo from './img/logo192.png';

import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";

const MenuLink = styled(Link)`
text-decoration: none;
color: #FFF;
text-align: center;
font-family : MonoPixel;
font-size: 20px;
font-style: normal;
font-weight: 300;
line-height: normal;
text-rendering: geometricPrecision;
text-transform: uppercase; 
color: #fff;
margin: 10px;
flex-shrink:1;

&:focus, &:visited, &:link, &:active {
    text-decoration: none;
    color: #fff;
}

&:hover {
    text-decoration: none;
    color: #00ff00;
}

`;

const MobileLink = styled(Link)`
text-decoration: none;
color: #FFF;
text-align: center;
font-family : MonoPixel;
font-size: 28px;
font-style: normal;
font-weight: 300;
line-height: normal;
text-rendering: geometricPrecision;
text-transform: uppercase; 
color: #fff;
margin: 10px;
flex-grow:1;

&:focus, &:visited, &:link, &:active {
    text-decoration: none;
    color: #fff;
}

&:hover {
    text-decoration: none;
    color: #00ff00;
}

`;

const Navigation = () => {

    const [superAdmin, setSuperAdmin] = useState(false);
    const [toggled, setToggled] = useState(false);


    const releaseAccess = process.env.REACT_APP_RELEASE_ACCESS;


    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider()
    const provider = new ethers.BrowserProvider(walletProvider);

    useEffect(() => {
        isSuperAdmin();
    }, []);

    useEffect(() => {
        isSuperAdmin();
    }, [address]);

    useEffect(() => {
        isSuperAdmin();
    }, [isConnected]);

    const isSuperAdmin = () => {
        if (isConnected) {
            if (address === superAdminAddress) {
                setSuperAdmin(true);
            } else {
                setSuperAdmin(false);
            }
        }
    }

    return (
        <div>
            <MediaQuery minWidth={parseInt(breakpoints.md)}>
                <nav id='desktopMenu'>
                    <LogoLink />
                    <MenuLink to={"/about"}>About</MenuLink>
                    <MenuLink to={"/collection/0"}>Collection</MenuLink>
                    <MenuLink to={"/my_cypherdudes"}>My Cypherdudes</MenuLink>
                    <MenuLink to={"/archives"}>Archives</MenuLink>
                    <MenuLink to={"/leaderboard"}>leaderboard</MenuLink>
                    <Link to="https://opensea.io/collection/cypherdudes" target="_blank">
                        <img className='extLogoLink' src={os_Logo} alt="Logo" />
                    </Link>
                    <Link to="https://etherscan.io/address/0x9d0f5277d19075ec1201ff94a483c5bcee718d20" target="_blank">
                        <img className='extLogoLink logoMarginLeft' src={etherscan} alt="Logo" />
                    </Link>
                    {superAdmin ? <MenuLink to={"/4dm1n"}>4dm1n</MenuLink> : ''}
                    <div id='connectDiv'>
                        <w3m-button label='CONNECT WALLET' style={{ float: 'right' }} />
                    </div>
                </nav>
            </MediaQuery>
            <MediaQuery maxWidth={parseInt(breakpoints.md)}>
                <Sidebar backgroundColor="#000000B3" style={{ minWidth: 'auto', border:'none' }} id='mobileMenu' className='mainMenu' onBackdropClick={() => setToggled(false)} toggled={toggled} breakPoint='always'>
                    <div className="mobileSideMenu">
                        <MobileLink onClick={() => setToggled(!toggled)} to={"/about"}>About</MobileLink>
                        <MobileLink onClick={() => setToggled(!toggled)} to={"/collection/0"}>Collection</MobileLink>
                        <MobileLink onClick={() => setToggled(!toggled)} to={"/my_cypherdudes"}>My Cypherdudes</MobileLink>
                        <MobileLink onClick={() => setToggled(!toggled)} to={"/archives"}>Archives</MobileLink>
                        <MobileLink onClick={() => setToggled(!toggled)} to={"/leaderboard"}>leaderboard</MobileLink>
                        <Link onClick={() => setToggled(!toggled)} to="https://opensea.io/collection/cypherdudes" target="_blank">
                            <img className='mobileLogoLink' src={os_Logo} alt="Logo" />
                        </Link>
                        <Link onClick={() => setToggled(!toggled)} to="https://etherscan.io/address/0x9d0f5277d19075ec1201ff94a483c5bcee718d20" target="_blank">
                            <img className='mobileLogoLink' src={etherscan} alt="Logo" />
                        </Link>
                    </div>

                </Sidebar>
                <nav>
                    <div id='MobileMenu'>
                        <button className='logoBtn' onClick={() => setToggled(!toggled)}>
                            <img className='logoLink' src={logo} alt="Logo" />
                        </button>
                        <div id='connectDiv'>
                            <w3m-button label='CONNECT WALLET' style={{ float: 'right' }} />
                        </div>
                    </div>


                </nav>
            </MediaQuery>
        </div>


    )
}

export default Navigation;
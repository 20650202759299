import React from "react";
import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { breakpoints } from '../components/constants';
import { ethers } from "ethers";
import CypherDudesArchivesArtifact from '../web3/abi/CypherDudesArchives.json';
import { contractAddresses } from '../web3/contractsAddresses';
import ArchivesCollectionTokenCard from "../components/archivesCollectionTokenCard";
import { v4 as uuidv4 } from 'uuid';
import { superAdminAddress } from '../web3/adminAddresses';
import styled from 'styled-components';
import schema from '../components/img/schema_archive.png';
import schemaMb from '../components/img/schema_archive_mb.png';

import Background from "../components/background";

import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";

const PageLink = styled(Link)`
  text-decoration: none;
  cursor: pointer
  &:focus, &:visited, &:link, &:active {
      text-decoration: none;
  }
`;

const utilities = require("../components/utilities");

const Archives = ({ paginator }) => {

    const params = useParams();
    const navigate = useNavigate();

    const [admin, setAdmin] = useState(false);
    const [dudeId, setDudeId] = useState(0);

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider()
    const provider = new ethers.BrowserProvider(walletProvider);
    const openProvider = new ethers.BrowserProvider(window.ethereum);
    const infuraProvider = new ethers.JsonRpcProvider("https://mainnet.infura.io/v3/a401f99315474f15bf32bb81404d0886");
    const LlamaProvider = new ethers.JsonRpcProvider("https://eth.llamarpc.com/sk_llama_e95422cce70e51a648a78b78dc29799f");

    const cypherDudesArchivesReadContract = new ethers.Contract(
        contractAddresses.CypherDudesArchives,
        CypherDudesArchivesArtifact.abi,
        LlamaProvider
    );

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (address === superAdminAddress) {
            setAdmin(true);
        }
    }, [address])

    const selectDude = () => {
        navigate(`/folder/${dudeId}`)
    }
    const openCollection = () => {
        navigate(`/archives_collection/0`)
    }

    const stepUp = () => {
        document.getElementById("inputDudeId").stepUp();
    }

    const stepDown = () => {
        document.getElementById("inputDudeId").stepDown();
    }


    return (
        <div>
            <Background />
            <div className="ProjectInfoContainer">
                <div className="ArchiveOptions">
                        <div className="archiveRoutes">
                            <p className="highlightInfo">CYPHERDUDES ARCHIVES FOLDERS</p>
                            <p>Enter an ID Number and hit OPEN FOLDER to browse through the archives made by this Cypherdude.</p>
                            <div className="adminTools">
                                <button className="uxBtn spinnerBtn" title="Decrease value" onClick={stepDown}>-</button>
                                <input id="inputDudeId" name="inputDudeId" placeholder="Cypherdude Id" className="inputId" type="number" onChange={e => setDudeId(e.target.value)} />
                                <button className="uxBtn spinnerBtn" title="Increase value" onClick={stepUp}>+</button>
                                <button className="uxBtn" onClick={selectDude}>OPEN FOLDER</button>
                            </div>
                        </div>

                        <div className="archiveRoutes">
                            <p className="highlightInfo">CYPHERDUDES ARCHIVES COLLECTION</p>
                            <p>You can also explore the entire archive collection here. They will be sorted by date of issuance from oldest to newest</p>

                            <div className="archiveCollection">
                                <button className="uxBtn" onClick={openCollection}>BROWSE ARCHIVES</button>
                            </div>
                        </div>


                </div>
                <br />
                <div className="projectInfo">
                    <p className="highlightInfo">THE CYPHERDUDES ARCHIVES</p>
                    <p>The Cypherdudes Archives is a collection growing from the Cypherdudes series. Its the recipient of all encrypted inscriptions for which owner has decided to keep an archive.</p>
                    <p>It aims to serve as an easy browsing feature for the cypherdudes encryption system.</p>
                    <p><span className="highlightInfo">HOW IT WORKS</span> : You need a cypherdude to mint archives. When an archive is minted, it wraps the encrypted message inside a cypherdude around a new erc721 token.</p>
                    <p>There are two types of archives :</p>
                    <p><span className="highlightInfo">PUBLIC</span> : The password used to encrypt the data will be made public during the mint process, and stored on the blockchain. Anyone will be able to access the archive content</p>
                    <p><span className="highlightInfo">PRIVATE</span> : The password used to encrypt the data will remain secret. The archive content won't be accessible unless you know the password.</p>
                    <br />
                    <MediaQuery minWidth={parseInt(breakpoints.md)}>
                        <div className="fullWidth"> <img className='Img100' src={schema} alt="Schema Cypherdudes Archives" /></div>
                    </MediaQuery>
                    <MediaQuery maxWidth={parseInt(breakpoints.md)}>
                        <div className="fullWidth"> <img className='Img100' src={schemaMb} alt="Schema Cypherdudes Archives" /></div>
                    </MediaQuery>

                </div>


            </div>

        </div>
    )
}

export default Archives;
import React, { useState, useEffect } from 'react';

function Countdown() {
    const targetTime = 1708614000; // Target timestamp to countdown from

    const calculateTimeRemaining = () => {
        const now = new Date().getTime();
        const difference = targetTime * 1000 - now; // Convert targetTime to milliseconds
        return difference > 0 ? Math.floor(difference / 1000) : 0;
    };

    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const formatTime = (timeInSeconds) => {
        const days = Math.floor(timeInSeconds / (3600 * 24));
        const hours = Math.floor((timeInSeconds % (3600 * 24)) / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = timeInSeconds % 60;
        return `${days.toString().padStart(2, '0')} : ${hours.toString().padStart(2, '0')} : ${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`;
  };

    return (
        <div>
            <h2>{formatTime(timeRemaining)}</h2>
        </div>
    );
}

export default Countdown;

import React, { useState, useEffect } from 'react';

const ProgressBar = ({ progress }) => {
  return (
    <div style={{ border: '1px solid #00ff00', width: '100%', borderRadius: '5px' }}>
      <div
        style={{
          width: `${progress}%`,
          backgroundColor: '#00ff00',
          height: '20px',
          borderRadius: '5px',
          transition: 'width 0.3s ease',
        }}
      />
    </div>
  );
};

export default ProgressBar;
import * as THREE from 'three';
import { useEffect, useRef } from "react";
import baseColorTexture from './misc/textures/polished_concrete_basecolor.jpg';
import alphaTexture from './misc/textures/cypherbits_alpha.jpg';


function Background() {
    const refContainer = useRef(null);
    useEffect(() => {
        let camera, scene, renderer, bulbLight, bulbMat, hemiLight;
        let floorMat, backMat;
        let mouseX = 0, mouseY = 0;
        let floorMesh, backMesh;

        const raycaster = new THREE.Raycaster();

        init();
        animate();

        function init() {

            camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 100);
            camera.position.set(0, 5, 0); // Static position facing the base plane
            camera.lookAt(0, 0, 0);

            scene = new THREE.Scene();

            const bulbGeometry = new THREE.SphereGeometry(0.02, 16, 8);
            bulbLight = new THREE.PointLight(0xffffff, 1, 150,2);
            bulbMat = new THREE.MeshStandardMaterial({
                emissive: 0xffffff,
                emissiveIntensity: 1,
                color: 0x000000
            });
            //bulbLight.add(new THREE.Mesh(bulbGeometry, bulbMat));
            bulbLight.position.set(0, 0.075, 0); // Static position at 1.25 units from the ground
            bulbLight.castShadow = true;
            scene.add(bulbLight);

            hemiLight = new THREE.HemisphereLight(0xddeeff, 0x0f0e0d, 0.02);
            scene.add(hemiLight);

            floorMat = new THREE.MeshBasicMaterial({
                color: 0x000000,
                alphaTest: 0.5,
                transparent: false,
                side: THREE.DoubleSide
            });
            backMat = new THREE.MeshPhysicalMaterial({
                roughness: 0.4,
                color: 0x8b8b8b,
                metalness: 0.2,
                bumpScale: 1
            });
            const textureLoader = new THREE.TextureLoader();
            textureLoader.load(alphaTexture, function (map) {

                map.wrapS = THREE.RepeatWrapping;
                map.wrapT = THREE.RepeatWrapping;
                map.repeat.set(20, 20);
                floorMat.alphaMap = map;
                floorMat.needsUpdate = true;

            });

            const floorGeometry = new THREE.PlaneGeometry(20, 20);
            floorMesh = new THREE.Mesh(floorGeometry, floorMat);
            floorMesh.receiveShadow = true;
            floorMesh.castShadow = true;
            floorMesh.rotation.x = - Math.PI / 2.0;
            floorMesh.position.y = 0.15;
            scene.add(floorMesh)

            const backGeometry = new THREE.PlaneGeometry(20, 20);
            backMesh = new THREE.Mesh(backGeometry, backMat);
            backMesh.receiveShadow = true;
            backMesh.rotation.x = - Math.PI / 2.0;
            scene.add(backMesh)


            renderer = new THREE.WebGLRenderer();
            renderer.shadowMap.enabled = true;
            renderer.toneMapping = THREE.ReinhardToneMapping;
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setSize(window.innerWidth, window.innerHeight);
            document.body.appendChild(renderer.domElement);

            window.addEventListener('resize', onWindowResize);
            document.addEventListener('mousemove', onMouseMove);
        }

        function onWindowResize() {

            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();

            renderer.setSize(window.innerWidth, window.innerHeight);

        }

        function onMouseMove(event) {
            // Convert mouse position to normalized device coordinates
            mouseX = (event.clientX / window.innerWidth) * 2 - 1;
            mouseY = -(event.clientY / window.innerHeight) * 2 + 1;
        }

        function animate() {
            requestAnimationFrame(animate);
            // Update the light position based on mouse coordinates
            raycaster.setFromCamera({ x: mouseX, y: mouseY }, camera);
            const intersection = raycaster.intersectObject(scene.children[3], true);

            if (intersection.length > 0) {
                const point = intersection[0].point;
                bulbLight.position.copy(point);
                bulbLight.position.y += 0.075;
            }
            renderer.render(scene, camera);
        }
        return () => {
            // Clear memory
            const canvas = renderer.domElement;
            canvas.remove();
            renderer.dispose();
        };
    }, [])
}

export default Background
import { Link } from 'react-router-dom';
import styled from 'styled-components';
const utilities = require("./utilities");

const TokenLink = styled(Link)`
display:block;
margin-bottom :10px;
&:focus, &:hover, &:visited, &:link, &:active {
    color:#fff;
}
&:hover{
    color:#00ff00;
}
`;


const ArchivesCollectionTokenCard = ({tokens}) => (
        <div className='tokenBox'>
            <div className='MyArchiveCard'><iframe sandbox="allow-scripts" frameBorder="0" srcDoc={tokens.animationURL}></iframe></div>
            <div className='MainArchiveInfo'>
                <TokenLink to={`/archive/${tokens.tokenId}`} className='TokenName'>{tokens.name}</TokenLink>
            </div>
        </div>
    )
export default ArchivesCollectionTokenCard;

import { Link } from 'react-router-dom';
import styled from 'styled-components';
const utilities = require("./utilities");

const TokenCardLink = styled(Link)`
display : block;
height: 300px ;
width: 300px ;
background-color: #00ff00;
`;

const ClaimLink = styled(Link)`
  text-decoration: none;
  cursor: pointer
  &:focus, &:hover, &:visited, &:link, &:active {
      text-decoration: none;
  }
`;

const parseHTML = (tokenURI) =>{
    console.log(tokenURI);
    const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
    //utilities.writeFile(path.join(__dirname, "output/metadata.json"), tokenURIDecoded);
	const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
    const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
	return {anim:animationURL, json: tokenURIJSONDecoded}
}


const ClaimWordTokenCard = (tokenURI) => (
        <div className='claimPreview'>
            <div><object className='tokenPreview' dangerouslySetInnerHTML={ {__html: parseHTML(tokenURI).anim}}></object></div>
            <div className='claimWordTokenInfo'>
                <p className='TokenName'>{parseHTML(tokenURI).json.name}</p>
                <button className="uxBtn">CLAIM WORD</button>
                
            </div>
        </div>
    )
export default ClaimWordTokenCard;

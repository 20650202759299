import React from "react";
import { useParams, useNavigate  } from 'react-router-dom';
import { useEffect, useState } from "react";
import { Signature, ethers } from "ethers";
import MediaQuery from 'react-responsive';
import { breakpoints } from '../components/constants';
import WordsCloud from "../components/wordsCloud";
import CypherDudesArtifact from '../web3/abi/CypherDudes.json';
import CypherDudesBitArtifact from '../web3/abi/CypherDudesBit.json';
import { contractAddresses } from '../web3/contractsAddresses';
import { v4 as uuidv4 } from 'uuid';
import ClaimWordTokenCard from "../components/claimWordTokenCard";

import Modal from 'react-modal';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";

import { bip39, tokenDB } from "../App";

const utilities = require("../components/utilities");

const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

const ClaimWord = ({ tokenId }) => {

    Modal.setAppElement('#root');

    const params = useParams();
    const navigate = useNavigate();

    const [tokenURI, setTokenURI] = useState({});
    const [parsedURI, setParsedURI] = useState('');
    const [tokenName, setTokenName] = useState('');
    const [secretWord, setSecretWord] = useState('');
    const [canceledWord, setCanceledWord] = useState('')
    const [_claimedWord, _setClaimedWord] = useState('');
    const [claimedWord, setClaimedWord] = useState('');
    const [txBeingSent, setTxBeingSent] = useState(undefined);
    const [txError, setTxError] = useState(undefined);
    const [modalContent, setModalContent] = useState('');
    const [updateResult, setUpdateResult] = useState('');
    const [claimable, setClaimable] = useState(false);

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider()
    const provider = new ethers.BrowserProvider(walletProvider);

    const id = params.tokenId;


    const [modalIsOpen, setIsOpen] = useState(false);
    let subtitle;
    const customStyles = {
        overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: '#000000B3'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            border: '1px solid #00ff00',
            background: '#000',
            borderRadius: '0px',
            transform: 'translate(-50%, -50%)',
        },
    };

    useEffect(() => {
        if (address !== undefined) {
            _getTokenURI()
                .catch(console.error);
        }
    }, []);

    useEffect(() => {
        var status = document.getElementById('status')
        if (status != null) {
            var statusBtn = document.getElementById('statusBtn')
            status.innerHTML = modalContent;
            statusBtn.style.display = 'block';
        }
    }, [modalContent]);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#00ff00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const getValueByTraitType = (jsonData, traitType) => {
        const trait = jsonData.find(trait => trait.trait_type === traitType)?.value || '';
        return trait;
    }

    const _getTokenURI = async () => {
        if (!isConnected) {
            return;
        } else {
            if (id < 1728){
                try {
                    setTokenURI({});
                    const signer = await provider.getSigner();
                    const cypherDudesContract = new ethers.Contract(
                        contractAddresses.CypherDudes,
                        CypherDudesArtifact.abi,
                        signer
                    );
                    if (address === await cypherDudesContract.ownerOf(id)) {
                        try {
                            let tokenURI = await cypherDudesContract.tokenURI(id);
                            const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                            const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                            const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                            const tokenEntry = {
                                id: uuidv4(),
                                tokenId: id.toString(),
                                name: tokenURIJSONDecoded.name,
                                traits: tokenURIJSONDecoded.attributes,
                                animationURL: animationURL
                            }
                            //tokenDB.doc(tokenEntry.id).set(tokenEntry);
                            setTokenURI(tokenEntry)
                            setTokenName(tokenURIJSONDecoded.name);
                            setSecretWord(getValueByTraitType(tokenURIJSONDecoded.attributes, 'Secret Word'));
                        } catch (error) {
                            console.log(error)
                        }
                    }
                } catch (error) {
                    console.log(error);
                    setTxError(error);
                } finally {
                }
            } else {
                try {
                    setTokenURI({});
                    const signer = await provider.getSigner();
                    const cypherDudesBitContract = new ethers.Contract(
                        contractAddresses.CypherDudesBit,
                        CypherDudesBitArtifact.abi,
                        signer
                    );
                    if (address === await cypherDudesBitContract.ownerOf(id)) {
                        try {
                            let tokenURI = await cypherDudesBitContract.tokenURI(id);
                            const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                            const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                            const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                            const tokenEntry = {
                                id: uuidv4(),
                                tokenId: id.toString(),
                                name: tokenURIJSONDecoded.name,
                                traits: tokenURIJSONDecoded.attributes,
                                animationURL: animationURL
                            }
                            //tokenDB.doc(tokenEntry.id).set(tokenEntry);
                            setTokenURI(tokenEntry)
                            setTokenName(tokenURIJSONDecoded.name);
                            setSecretWord(getValueByTraitType(tokenURIJSONDecoded, 'Secret Word'));
                        } catch (error) {
                            console.log(error)
                        }
                    }
                } catch (error) {
                    console.log(error);
                    setTxError(error);
                } finally {
                }
            }
            
        }
    }

    const updateTokenURI = async () => {
        try {
            const signer = await provider.getSigner();

            const cypherDudesContract = new ethers.Contract(
                contractAddresses.CypherDudes,
                CypherDudesArtifact.abi,
                signer
            );
            let tokenURI = await cypherDudesContract.tokenURI(id);
            const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
            const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
            const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
            const tokenUpdate = {
                id: uuidv4(),
                tokenId: id.toString(),
                name: tokenURIJSONDecoded.name,
                traits: tokenURIJSONDecoded.attributes,
                animationURL: animationURL
            }
            let j = 0;
            tokenDB.where("tokenId", "==", id.toString())
                .get()
                .then(async (querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        j++
                        tokenDB.doc(doc.data().id).delete();
                        tokenDB.doc(tokenUpdate.id).set(tokenUpdate);
                        setTokenURI(tokenUpdate)
                    })
                    if (j < 1) {
                        tokenDB.doc(tokenUpdate.id).set(tokenUpdate);
                        setTokenURI(tokenUpdate)
                    }
                })
        } catch (error) {
            console.log(error)
        }

    }

    const changeWord = (word, isClaimed) => {
        if (isClaimed) {
            setClaimedWord('claimed');
        } else {
            setClaimedWord(word);
        }
    }

    const claimWord = async (targetWord) => {
        try {
            setCanceledWord('');
            _setClaimedWord(targetWord);
            const updatedData = {
                word: targetWord,
                tokenId: id
            }
            let i = 0;
            const docRef = bip39.doc(targetWord);
            const docSnapshot = await docRef.get();

            if (docSnapshot.exists) {
                const docData = docSnapshot.data();
                console.log(docData.claimed);
                // Check if the word is already claimed
                if (docData.claimed === true) {
                    setClaimable(false);
                } else {
                    // Update the claimed field of the document to true
                    await docRef.update({ claimed: true });
                    setClaimable(true);
                }
            } else {
                console.log("Document does not exist.");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const cancelClaim = async (targetWord) => {
        try {
            let i = 0;
            const docRef = bip39.doc(targetWord);
            const docSnapshot = await docRef.get();

            if (docSnapshot.exists) {
                await docRef.update({ "claimed": false });
                await docRef.update({ tokenId: null });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const _dismissTransactionError = () => {
        setTxError(undefined);
    }

    const signMessage = async () => {
        if (!isConnected) {
            return;
        }
        if (claimedWord.length === 0) {
            return;
        }
        
        try {
            openModal();
            if (secretWord.length > 0){
                setModalContent(`You already claimed a word for your Cypherdude`)
                return;
            }
            const docRef = bip39.doc(claimedWord.toLowerCase());
            const docSnapshot = await docRef.get();

            if (docSnapshot.exists) {
                const docData = docSnapshot.data();
                console.log(docData.claimed);
                // Check if the word is already claimed
                if (docData.claimed === true) {
                    setModalContent(`${claimedWord} has just been claimed by someone else.`)
                } else {
                    // Update the claimed field of the document to true
                    await docRef.update({ claimed: true });
                    await docRef.update({ tokenId: parseInt(id) });
                    const signer = await provider.getSigner();

                    if( id < 1728 ){
                        const cypherDudesContract = new ethers.Contract(
                            contractAddresses.CypherDudes,
                            CypherDudesArtifact.abi,
                            signer
                        );
                        _dismissTransactionError();
                        let rawSig = await signer.signMessage(claimedWord);
                        let sig = Signature.from(rawSig);
                        const tx = await cypherDudesContract.signedClaimWord(claimedWord, sig, id);
                        tx.wait().then(async (receipt) => {
                            if (receipt.status === 0) {
                                cancelClaim(claimedWord.toLowerCase());
                                setModalContent(`transaction failed`);
                                throw new Error("transaction failed");
                            }
                            //updateTokenURI();
                            setModalContent(`${claimedWord} claimed successfully`)
                            setSecretWord(claimedWord);
                            setTimeout(()=>{
                                navigate(`/cypherdude/${id}`)
                            },2000);
                        })
                    } else {
                        const cypherDudesBitContract = new ethers.Contract(
                            contractAddresses.CypherDudesBit,
                            CypherDudesBitArtifact.abi,
                            signer
                        );
                        _dismissTransactionError();
                        let rawSig = await signer.signMessage(claimedWord);
                        let sig = Signature.from(rawSig);
                        const tx = await cypherDudesBitContract.signedClaimWord(claimedWord, sig, id);
                        tx.wait().then(async (receipt) => {
                            if (receipt.status === 0) {
                                cancelClaim(claimedWord.toLowerCase());
                                setModalContent(`transaction failed`);
                                throw new Error("transaction failed");
                            }
                            //updateTokenURI();
                            setModalContent(`${claimedWord} claimed successfully`);
                            setSecretWord(claimedWord);
                            setTimeout(()=>{
                                navigate(`/cypherdude/${id}`)
                            },2000);
                        })
                    }

                    
                }
            } else {
                console.log("Document does not exist.");
            }
        } catch (error) {
            if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                setModalContent(`Request aborted by the user`);
                cancelClaim(claimedWord.toLowerCase());
                return;
            }
            cancelClaim(claimedWord.toLowerCase());
            setModalContent(`transaction failed`);
            console.log(error);
            setTxError(error);
        } finally {
            setTxBeingSent(undefined);
        }
    }

    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                style={customStyles}
                contentLabel="Inscription Complete">
                <h2 id="status" ref={(_subtitle) => (subtitle = _subtitle)}>Pending...</h2>
                <button id="statusBtn" className="uxBtn modal" onClick={closeModal}>CLOSE</button>
            </Modal>
            <WordsCloud changeWord={changeWord} canceledWord={canceledWord} claimedWord={_claimedWord} />
            <MediaQuery minWidth={parseInt(breakpoints.md)}>
                <div className='claimPreview'>
                    <div className='tokenPreview'><iframe sandbox="allow-scripts" frameBorder="0" srcDoc={tokenURI.animationURL}></iframe></div>
                    <div className='claimWordTokenInfo'>
                        <p className='claimWordTokenName'>{tokenName}</p>
                        {secretWord.length > 0 ? <button id='claimBtn' type="button" className="disabledClaimWordUxBtn">WORD ALREADY CLAIMED</button> : claimedWord.length === 0 ? <button id='claimBtn' type="button" className="disabledClaimWordUxBtn">CLICK ON A WORD TO CLAIM</button> : claimedWord === 'claimed' ? <button id='claimBtn' type="button" className="disabledClaimWordUxBtn">THAT WORD IS ALREADY CLAIMED</button> : <button id='claimBtn' type="button" onClick={signMessage} className="claimWordUxBtn">CLAIM :  {claimedWord}</button>}

                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={parseInt(breakpoints.md)}>
                <div className='claimPreview'>
                    <div className='claimWordTokenInfo'>
                        <p className='claimWordTokenName'>{tokenName}</p>
                        {secretWord.length > 0 ? <button id='claimBtn' type="button" className="disabledClaimWordUxBtn">WORD ALREADY CLAIMED</button> : claimedWord.length === 0 ? <button id='claimBtn' type="button" className="disabledClaimWordUxBtn">CLICK ON A WORD TO CLAIM</button> : claimedWord === 'claimed' ? <button id='claimBtn' type="button" className="disabledClaimWordUxBtn">THAT WORD IS ALREADY CLAIMED</button> : <button id='claimBtn' type="button" onClick={signMessage} className="claimWordUxBtn">CLAIM :  {claimedWord}</button>}

                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default ClaimWord;
import * as THREE from 'three';
import { useEffect, useRef } from "react";
import baseColorTexture from './misc/textures/polished_concrete_basecolor.jpg';
import normalTexture from './misc/textures/CYPHERBITS_n.png';

import { Reflector } from './misc/Reflector.js'
import { ReflectorMaterial } from './misc/ReflectorMaterial.js'

function MainBoard() {
    const refContainer = useRef(null);
    useEffect(() => {

        let scene, camera, renderer, backPlaneMaterial, videoTexture, video;
        let mouseX = 0, mouseY = 0;
        const backPlane = createBackPlane();

        init();
        animate();

        const raycaster = new THREE.Raycaster();
        const mouse = new THREE.Vector2();

        async function init() {
            // Scene
            scene = new THREE.Scene();

            // Camera
            camera = new THREE.PerspectiveCamera(
                60,
                window.innerWidth / window.innerHeight,
                0.1,
                1000
            );
            camera.position.set(0, 1.95, 2.5);


            // Renderer
            renderer = new THREE.WebGLRenderer({ antialias: true, pixelRatio: window.devicePixelRatio });
            renderer.setSize(window.innerWidth, window.innerHeight);
            renderer.setPixelRatio(window.devicePixelRatio);
            document.body.appendChild(renderer.domElement);

            // Handle window resize
            window.addEventListener('resize', onWindowResize);

            // Handle mouse movement
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('click', onClick);

            // add back plane
            scene.add(backPlane);
            camera.lookAt(backPlane.position);

            await addReflector(3.5, 3.5, 0, 0, -Math.PI / 2, 0, 10) // Bottom
            //await addReflector(3.5, 3.5, 0, 3.9, Math.PI / 2, 0, 2) // Bottom
        }

        function createBackPlane() {
            // Load video texture and apply it to the back face of the cube
            video = document.createElement('video');
            video.src = '/assets/Alpha2.mp4'; // replace with your video file
            video.loop = true;
            video.muted = true;
            video.play();

            videoTexture = new THREE.VideoTexture(video);
            videoTexture.wrapS = THREE.RepeatWrapping;
            videoTexture.wrapT = THREE.RepeatWrapping;

            const backPlaneGeometry = new THREE.PlaneGeometry(3.5, 3.5);
            backPlaneMaterial = new THREE.MeshBasicMaterial({ map: videoTexture, side: THREE.FrontSide });
            const backPlane = new THREE.Mesh(backPlaneGeometry, backPlaneMaterial);
            backPlane.position.set(0, 1.95, -1.75);
            return backPlane;
        }

        function onClick(event){
            raycaster.setFromCamera(mouse, camera);
            const intersects = raycaster.intersectObjects(scene.children, true);
            if (intersects.length > 0) {
                const selectedObject = intersects[0].object;
                if(selectedObject.position.z === -1.75 ){
                    video.muted = !video.muted;
                }
            }
        }

        async function addReflector(b, h, x, y, dx, dy, k) {
            var reflector = new Reflector();
            var geometry = new THREE.PlaneGeometry(20, 20);
            var [map, normalMap] = await Promise.all([
                new THREE.TextureLoader().load(baseColorTexture),
                new THREE.TextureLoader().load(normalTexture)
            ]);
            map.wrapS = THREE.RepeatWrapping;
            map.wrapT = THREE.RepeatWrapping;

            normalMap.wrapS = THREE.RepeatWrapping;
            normalMap.wrapT = THREE.RepeatWrapping;

            const material = new ReflectorMaterial({
                map,
                normalMap,
                normalScale: new THREE.Vector2(20, 20),
                mirror: 1,
                mixStrength: k,
                dithering: true
            });
            material.uniforms.tReflect = reflector.renderTargetUniform;
            material.uniforms.uMatrix = reflector.textureMatrixUniform;
            var mesh = new THREE.Mesh(geometry, material);
            mesh.add(reflector);
            mesh.position.x = x;
            mesh.position.y = y;
            mesh.rotation.x = dx;
            mesh.rotation.y = dy;
            reflector.setSize(window.innerWidth * window.devicePixelRatio, window.innerHeight * window.devicePixelRatio)
            mesh.onBeforeRender = (renderer, scene, camera) => {
                mesh.visible = false;
                reflector.update(renderer, scene, camera);
                mesh.visible = true;
            };
            scene.add(mesh);
        }

        function animate() {
            requestAnimationFrame(animate);

            // Move the cube in the same plane as the back face
            const targetX = (mouseX - camera.position.x) * 0.05;
            // Constrain the cube's movement within a certain range
            const minXLimit = -1;
            const maxXLimit = 1;
            camera.position.x = Math.max(minXLimit, Math.min(maxXLimit, camera.position.x + targetX));


            // Update the camera's lookAt vector to point to the center of the back face
            camera.lookAt(backPlane.position);

            const targetZ = 7 + mouseY * 5;
            camera.position.z = THREE.MathUtils.lerp(camera.position.z, targetZ, 0.05);

            renderer.render(scene, camera);
        }

        function onWindowResize() {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();

            renderer.setSize(window.innerWidth, window.innerHeight);
        }

        function onMouseMove(event) {
            mouseX = (event.clientX / window.innerWidth) * 2 - 1;
            mouseY = -(event.clientY / window.innerHeight) * 2 + 1;
            mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
        }

        return () => {
            // Clear memory
            const canvas = renderer.domElement;
            canvas.remove();
            video.src = '';
            video.load();
            video.remove();
            videoTexture.dispose();
            backPlaneMaterial.dispose();
            renderer.dispose();
        };
    }, [])
}

export default MainBoard
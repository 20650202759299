import React from 'react';
import { Link } from 'react-router-dom';
import logo from './img/logo192.png';


const LogoLink = () => {
    return (
      <Link to="/">
        <img className='logoLink' src={logo} alt="Logo" />
      </Link>
    );
  };
  
  export default LogoLink;
  